<template>
  <v-card>
    <v-card-title> Mandates </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" md="4">
          <v-select
            v-model="filters.customerIds"
            :items="customersList"
            item-text="value"
            item-value="key"
            placeholder="Customer"
            v-on:change="getData"
            multiple
            dense
            chips
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            v-model="filters.statuses"
            :items="['Pending Approval', 'Active', 'In Active']"
            placeholder="Mandate Status"
            v-on:change="getData"
            multiple
            dense
            chips
            clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="mandatesList"
      item-key="full_name"
      class="table-rounded"
      dense
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <!-- name -->
      <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>
			<template #[`item.transaction_no`]="{ item }">
				{{ item.transaction_no }} / {{ item.total_transaction }}
			</template> -->
      <!-- status -->
      <!-- <template #[`item.status`]="{ item }">
				<v-chip small :color="statusColor[status[item.status]]" class="font-weight-medium">
					{{ status[item.status] }}
				</v-chip>
			</template> -->
      <template #[`item.startDate`]="{ item }">
        {{ item.startDate | format_utc_date_local_timezone }}
      </template>
      <template #[`item.endDate`]="{ item }">
        {{ item.endDate | format_date_local_timezone }}
      </template>
      <template #[`item.maxAmount.amount`]="{ item }">
        {{ item.maxAmount.amount | format_money(item.maxAmount.currency) }}
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="
            item.status == 'Completed'
              ? 'success'
              : item.status == 'Aborted'
              ? 'error'
              : item.status == 'Partially Complete'
              ? 'warning'
              : ''
          "
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template #[`item.bankCode`]="{ item }">
        {{ item.bankCode ? `${item.bankCode},${item.authType} (${item.method})` : '' }}
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn-toggle>
          <v-btn
            elevation="8"
            :disabled="item.isDefault"
            x-small
            v-on:click="defaultMandateConfirmation(item.id)"
            title="Set as a default"
          >
            Set as a default
          </v-btn>
          <!-- <v-btn x-small v-on:click="deletePaymentConfirmation(item.id)" title="Delete Payment">
            <v-icon>{{ icons.mdiDeleteCircleOutline }}</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            small
            outlined
            v-on:click="showTransactions(item.sellerInvoiceNo)"
            title="Show transactons"
            ><v-icon>{{ icons.mdiFormatListChecks }}</v-icon>
          </v-btn> -->
        </v-btn-toggle>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiCircleEditOutline,
  mdiFormatListChecks,
  mdiDeleteCircleOutline,
  mdiSend,
} from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'

export default {
  props: {
    customerID: Number,
  },
  setup() {
    const loading = ref(false)
    const deleteDialog = ref(false)
    const deleteLoading = ref(false)
    const mandatesList = ref([])
    const customersList = ref([])
    const deleteObj = reactive({})
    return {
      headers: [
        { text: 'Company', value: 'companyName' },
        { text: 'Start Date', value: 'startDate' },
        { text: 'End Date', value: 'endDate' },
        { text: 'Value', value: 'maxAmount.amount' },
        { text: 'Bank', value: 'bankCode' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'action' },
      ],
      mandatesList,
      customersList,
      loading,
      filters: {
        customerIds: [],
        statuses: ['Active'],
      },
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiCircleEditOutline,
        mdiDeleteCircleOutline,
        mdiSend,
        mdiFormatListChecks,
      },
      deleteDialog,
      deleteObj,
      deleteLoading,
    }
  },
  mounted() {
    let e = this
    let customerID = e.$route.params.customerID

    e.filters.customerIds = []
    e.customerID ? e.filters.customerIds.push(e.customerID) : false
    customerID && customerID > 0 ? e.filters.customerIds.push(parseInt(customerID)) : false

    e.getCustomerDDL()
    e.getData()
  },
  methods: {
    getCustomerDDL: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/Customer/get-customers-ddl')
        .then(function(response) {
          e.customersList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    getData: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/mandate/get-paged-mandates', {
        params: {
          CustomerIds: e.filters.customerIds && e.filters.customerIds.length ? e.filters.customerIds : [],
          MandateStatuses: e.filters.statuses && e.filters.statuses.length ? e.filters.statuses : [],
        },
      })
        .then(function(response) {
          e.mandatesList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    defaultMandateConfirmation: function(id) {
      let e = this
      e.loading = true
      Util.Axios.post(`/Mandate/${id}/set-as-default-mandate`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.successMessage('Default mandate set successfully.')
            e.getData()
          }
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
  },
}
</script>
